import React, { createContext, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();

  const handleUserPageNav = useCallback(
    (userId) => {
      navigate(`/user/${userId}`);
    },
    [navigate]
  );

  const handleHomeNav = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const handleNotificationsNav = useCallback(
    (userId) => {
      navigate(`/notifs/${userId}`);
    },
    [navigate]
  );

  return (
    <NavigationContext.Provider
      value={{ handleUserPageNav, handleHomeNav, handleNotificationsNav }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
