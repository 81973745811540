import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import styles from './Player.module.scss';

const Player = forwardRef(({ Url }, ref) => {
  const [videoId, setVideoId] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const playerRef = useRef(null);
  const containerRef = useRef(null);

  const extractVideoId = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const matches = url.match(regex);
    return matches ? matches[1] : null;
  };

  useEffect(() => {
    const id = extractVideoId(Url);
    setVideoId(id);
    setIsReady(false);

    // Delay setting isReady to true to ensure the DOM is ready
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 100);

    const currentPlayer = playerRef.current;

    return () => {
      clearTimeout(timer);
      if (
        currentPlayer &&
        currentPlayer.plyr &&
        typeof currentPlayer.plyr.destroy === 'function'
      ) {
        currentPlayer.plyr.destroy();
      }
    };
  }, [Url]);

  useImperativeHandle(ref, () => ({
    destroy: () => {
      if (
        playerRef.current &&
        playerRef.current.plyr &&
        typeof playerRef.current.plyr.destroy === 'function'
      ) {
        playerRef.current.plyr.destroy();
      }
    },
  }));

  if (!videoId) {
    return <div className={styles.error}>Invalid YouTube URL</div>;
  }

  const videoSrc = {
    type: 'video',
    sources: [
      {
        src: videoId,
        provider: 'youtube',
      },
    ],
  };

  return (
    <div ref={containerRef} className={styles.player}>
      {!isReady && <div className={styles.loading}>Loading player...</div>}
      {isReady && (
        <Plyr
          ref={playerRef}
          source={videoSrc}
          options={{
            ratio: '16:9',
            youtube: {
              noCookie: true,
              rel: 0,
              showinfo: 0,
              iv_load_policy: 3,
              modestbranding: 1,
            },
          }}
        />
      )}
    </div>
  );
});

export default Player;
