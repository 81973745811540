import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styles from './CommentList.module.scss';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog/ConfirmDeleteDialog';
import { Link } from 'react-router-dom';
import OverflowMenu from '../utils/OverflowMenu';
import { useUser } from '../../config/UserContext';
import { useSongs } from '../../config/SongContext';
import LikeButton from '../LikeButton';
import DOMPurify from 'dompurify';


const CommentList = ({ comments, currentUser, onDelete, songId, handleUserPageNavFromSong }) => {
  const { likeComment, unlikeComment } = useSongs();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedCommentIndex, setSelectedCommentIndex] = useState(null);
  const [userAvatars, setUserAvatars] = useState({});

  const { getUserDetailsById } = useUser();

  const handleLike = (songId, commentIndex, userId) => {
    likeComment(songId, commentIndex, userId);
  };

  const handleUnlike = (songId, commentIndex, userId) => {
    unlikeComment(songId, commentIndex, userId);
  };

  useEffect(() => {
    const fetchAvatars = async () => {
      const uniqueUserIds = [
        ...new Set(comments.map((comment) => comment.userId)),
      ];
      const avatars = {};

      for (const userId of uniqueUserIds) {
        try {
          const userDetails = await getUserDetailsById(userId);
          avatars[userId] = userDetails.avatar;
        } catch (error) {
          console.error('Error fetching user avatar:', error);
          avatars[userId] = defaultAvatar; // Set default avatar in case of error
        }
      }

      setUserAvatars(avatars);
    };

    if (comments && comments.length > 0) {
      fetchAvatars();
    }
  }, [comments, getUserDetailsById]);

  const handleOpenConfirmDialog = (index) => {
    setSelectedCommentIndex(index);
    setShowConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    if (selectedCommentIndex !== null) {
      onDelete(selectedCommentIndex);
    }
    // Reset
    setShowConfirmDialog(false);
    setSelectedCommentIndex(null);
  };

  const defaultAvatar = '../avatar.png';

  const linkify = (text) => {
    const urlRegex = /((https?:\/\/)[^\s]+)/g;
    return text.replace(urlRegex, (url) => {
      const shortenedUrl = shortenUrl(url, 24); // Shorten the URL to 24 characters
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${shortenedUrl}</a>`;
    });
  };

  const shortenUrl = (url, maxLength) => {
    const cleanUrl = url.replace(/^https?:\/\/www\./i, '');
    if (cleanUrl.length <= maxLength) {
      return cleanUrl;
    }
    const halfLength = Math.floor(maxLength / 2);
    return (
      cleanUrl.substring(0, halfLength) +
      '...' +
      cleanUrl.substring(cleanUrl.length - halfLength)
    );
  };

  const renderText = (text) => {
    const linkedText = linkify(text);
    const sanitizedHTML = DOMPurify.sanitize(linkedText);
    return (
      <p
        className={styles.commentText}
        dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      />
    );
  };

  const formatTimestamp = (timestamp) => {
    if (timestamp && typeof timestamp.toDate === 'function') {
      return moment(timestamp.toDate()).fromNow();
    } else if (timestamp instanceof Date) {
      return moment(timestamp).fromNow();
    } else if (timestamp && timestamp.seconds) {
      return moment.unix(timestamp.seconds).fromNow();
    } else {
      return 'Unknown time';
    }
  };

  return (
    <div className={styles.comments}>
      Comments: 
      <div className={styles.commentList}>
        {comments?.map((comment, index) => {
          const menuOptions1 = [
            {
              label: 'Delete',
              handler: () => handleOpenConfirmDialog(index),
              style: { color: '#EF4444' },
            },
          ];
          return (
            <div className={styles.container} key={index}>
              <div className={styles.userCluster}>
                <Link
                  to={`/user/${comment.userId}`}
                  className={styles.userLink}
                  onClick={(e) => {
                    e.preventDefault();
                    handleUserPageNavFromSong(comment.userId);
                  }}
                >
                  <img
                    src={userAvatars[comment.userId] || defaultAvatar}
                    alt={comment.userName || 'Unknown User'}
                    className={styles.avatar}
                  />
                  <div className={styles.infoStacked}>
                    <span className={styles.userName}>
                      {comment.userName || comment.displayName || 'Unknown User'}
                    </span>
                    <span className={styles.postTime}>
                      {formatTimestamp(comment.timestamp)}
                    </span>
                  </div>
                </Link>
                {currentUser && comment.userId === currentUser?.uid && (
                  <OverflowMenu options={menuOptions1} />
                )}
              </div>
              <div className={styles.commentBody}>
                {renderText(comment.commentText || comment.text)}
                <LikeButton
                  isLiked={comment.likes?.includes(currentUser?.uid)}
                  onLike={() => handleLike(songId, index, currentUser?.uid)}
                  onUnlike={() => handleUnlike(songId, index, currentUser?.uid)}
                  likeCount={comment.likes?.length || 0}
                />
              </div>
            </div>
          );
        })}
        {showConfirmDialog && (
          <ConfirmDeleteDialog
            show={showConfirmDialog}
            message='Are you sure you want to delete your comment?'
            onClose={() => setShowConfirmDialog(false)}
            confirm={handleConfirmDelete}
          />
        )}
      </div>
    </div>
  );
};

export default CommentList;
