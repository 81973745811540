import React, { useState } from 'react';
import { getFirestore, doc, deleteDoc } from 'firebase/firestore';
import Modal from '../utils/Modal';
import PostForm from '../PostForm';
import { useUser } from '../../config/UserContext';
import { useSongs } from '../../config/SongContext';
import hasPostedToday from '../utils/hasPostedToday';
import styles from './PostFormModal.module.scss';

const db = getFirestore();

const deleteSong = async (songId) => {
  const songRef = doc(db, 'songs', songId);
  await deleteDoc(songRef);
};

const PostFormModal = ({ show, onClose, isEditing = false, postToEdit = null }) => {
  const { currentUser } = useUser();
  const { songs } = useSongs();
  const [isPosting, setIsPosting] = useState(false);

  const handlePostingChange = (posting) => {
    setIsPosting(posting);
  };

  const todaysSong = currentUser
    ? hasPostedToday(songs, currentUser.uid)
    : null;

  const hasPostedResult = currentUser
    ? hasPostedToday(songs, currentUser.uid)
    : null;

  const thumbnailURL = hasPostedResult ? hasPostedResult.thumbnailURL : null;

  return (
    <Modal show={show} onClose={onClose} title={isEditing ? "Edit Post" : "Post a song"}>
      {todaysSong && !isEditing ? (
        <div className={styles.container}>
          <div className={styles.group}>
            <div className={styles.card}>
              <h3 className={styles.title}>
                You've already posted today!
              </h3>
              <div className={styles.songInfo}>
                <div className={styles.songInfoInner}>
                  <img src={thumbnailURL} alt='thumbnail' />
                  <span className={styles.songName}>{todaysSong.songName}</span>
                  <span className={styles.artist}>{todaysSong.songArtist}</span>
                </div>
                <button
                  className={styles.deleteButton}
                  onClick={() => deleteSong(todaysSong.id)}
                >
                  Delete Song
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={styles.loadingContainer}
          style={{ height: '100%', width: '100%' }}
        >
          {isPosting ? (
            <p className={styles.postingText}>{isEditing ? "Updating..." : "Posting..."}</p>
          ) : (
            <PostForm
              onPostingChange={handlePostingChange}
              onSuccess={onClose}
              isEditing={isEditing}
              postToEdit={postToEdit}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default PostFormModal;
