import React, { useState, useEffect, useRef, forwardRef } from 'react';
import styles from './CommentForm.module.scss';
import Send from '../Icons/Send';
import Spinner from '../Spinner/Spinner';

const CommentForm = forwardRef(({ currentUser, addComment, songId }, ref) => {
  const [commentText, setCommentText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const textareaRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleAddComment = async () => {
    if (currentUser && commentText.trim() !== '' && !isSubmitting) {
      setIsSubmitting(true);
      const newComment = {
        userId: currentUser.uid,
        displayName: currentUser.displayName,
        text: commentText.trim(),
      };

      try {
        await addComment(songId, newComment);
        setCommentText('');
        resetTextareaHeight();
        setIsFocused(false);
      } catch (error) {
        console.error("Error adding comment:", error);
        // Optionally, show an error message to the user
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const resetTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '40px';
      adjustTextareaHeight();
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '40px';
      const scrollHeight = textarea.scrollHeight;
      textarea.style.height = scrollHeight + 'px';
    }
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener('input', adjustTextareaHeight);
      return () => {
        textarea.removeEventListener('input', adjustTextareaHeight);
      };
    }
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [commentText]);
  return (
    <div className={styles.container}>
      <img
        className={styles.avatar}
        src={currentUser.avatar}
        alt={currentUser.displayName}
      />
      <div className={`${styles.textareaWrapper} ${isSubmitting ? styles.submitting : ''}`}>
        <textarea
          ref={textareaRef}
          id="comment-form"
          className={styles.commentForm}
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          placeholder='Add a comment'
          disabled={isSubmitting}
        ></textarea>
        {isSubmitting && <div className={styles.overlay}></div>}
      </div>
      {(isFocused || commentText.trim() !== '') && (
        <div className={styles.buttonContainer}>
          {isSubmitting ? (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            <button
              className={styles.postButton}
              onClick={handleAddComment}
              disabled={commentText.trim() === ''}
            >
              <Send fill={commentText.trim() === '' ? '#999' : '#171717'} />
            </button>
          )}
        </div>
      )}
    </div>
  );
});

export default CommentForm;