import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const handleAuthStateChange = async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            setCurrentUser({ ...userDoc.data(), uid: user.uid });
          } else {
            setCurrentUser(user);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setCurrentUser(null);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);

    return () => unsubscribe();
  }, [auth, db]);

  const fetchLikedSongsByUser = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();

      if (userData && Array.isArray(userData.likedSongs)) {
        const validLikedSongs = userData.likedSongs.filter(
          (songObj) => songObj && songObj.songId
        );

        const likedSongIDs = validLikedSongs.map((songObj) => songObj.songId);

        const likedSongsPromises = likedSongIDs.map(async (songId) => {
          const songSnapshot = await getDoc(doc(db, 'songs', songId));

          return songSnapshot.exists()
            ? { id: songSnapshot.id, ...songSnapshot.data() }
            : null;
        });

        const likedSongs = (await Promise.all(likedSongsPromises)).filter(
          Boolean
        );

        return likedSongs;
      } else {
        console.warn(
          'likedSongs is not an array or does not exist for user:',
          userId,
          userData
        );
        return [];
      }
    } catch (error) {
      console.error('Error fetching liked songs:', error);
      return [];
    }
  };

  const getUserAvatar = async (userId) => {
    const storage = getStorage();
    const avatarRef = ref(storage, `avatars/${userId}`);

    try {
      const url = await getDownloadURL(avatarRef);
      return url;
    } catch (error) {
      console.error(`Failed to fetch avatar for user ${userId}:`, error);
      return './avatar.png'; // return the default avatar
    }
  };

  const getUserDetailsById = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return {
          uid: userId,
          ...userData,
          avatar: userData.avatar || (await getUserAvatar(userId)),
          userName: userData.userName || 'Unknown User',
        };
      } else {
        console.error(`User with ID ${userId} does not exist`);
        return {
          avatar: await getUserAvatar(userId),
          userName: 'Unknown User',
        };
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      return null;
    }
  };

  return (
    <UserContext.Provider
      value={{ currentUser, getUserDetailsById, fetchLikedSongsByUser, db }}
    >
      {children}
    </UserContext.Provider>
  );
};
