import { useState, useEffect } from 'react';
import { getFirestore, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { auth } from '../../config/firebase';
import styles from './PostForm.module.scss';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Back from '../Icons/Back';
import { Link } from 'react-router-dom';

const extractSongDetails = (channelTitle, videoTitle) => {
  if (channelTitle.endsWith('- Topic')) {
    const artistName = channelTitle.slice(0, -7);
    return {
      songName: videoTitle,
      songArtist: artistName,
    };
  } else {
    const titlePattern = /^(.+) - (.+)$/;
    const match = videoTitle.match(titlePattern);
    if (match) {
      return {
        songName: match[2].trim(),
        songArtist: match[1].trim(),
      };
    }
  }
  return {
    songName: videoTitle,
    songArtist: '',
  };
};

const fetchVideoDetails = async (videoId) => {
  try {
    const API_KEY = process.env.REACT_APP_YTAPI;
    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${API_KEY}`
    );
    const data = await response.json();
    if (data.items && data.items.length > 0) {
      const { title, thumbnails, channelTitle } = data.items[0].snippet;
      return { snippet: { title, thumbnails, channelTitle } };
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching video details:', error);
    return null;
  }
};

const PostForm = ({ onSuccess, onPostingChange, isEditing = false, postToEdit = null }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUrlValid, setIsUrlValid] = useState(false);
  const [showFullForm, setShowFullForm] = useState(false);
  const [fetchedTitle, setFetchedTitle] = useState('');
  const [fetchedThumbnail, setFetchedThumbnail] = useState('');
  const [songName, setSongName] = useState('');
  const [songArtist, setSongArtist] = useState('');
  const [songURL, setSongURL] = useState('');
  const [description, setDescription] = useState('');
  const MAX_CHARS = 1000;
  const [remainingChars, setRemainingChars] = useState(MAX_CHARS);

  useEffect(() => {
    if (isEditing && postToEdit) {
      setSongName(postToEdit.songName);
      setSongArtist(postToEdit.songArtist);
      setSongURL(postToEdit.URL);
      setDescription(postToEdit.description);
      setFetchedTitle(postToEdit.songName);
      setFetchedThumbnail({ default: { url: postToEdit.thumbnailURL } });
      setShowFullForm(true);
      setIsUrlValid(true);
    }
  }, [isEditing, postToEdit]);

  const clearUrlInput = () => {
    setSongURL('');
    setIsUrlValid(false);
    setShowFullForm(false);
  };

  const normalizeYouTubeURL = (url) => {
    const parsedURL = new URL(url);
    if (parsedURL.hostname === 'music.youtube.com') {
      parsedURL.hostname = 'www.youtube.com';
    }
    const videoId = parsedURL.searchParams.get('v');
    parsedURL.search = videoId ? `?v=${videoId}` : '';
    return parsedURL.toString();
  };

  const handleUrlChange = async (e) => {
    let songURL = e.target.value;
    if (songURL.includes('music.youtube.com')) {
      songURL = normalizeYouTubeURL(songURL);
    }

    let videoId = null;

    if (songURL.match(/^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+/)) {
      setIsUrlValid(true);
      setSongURL(songURL);

      if (songURL.includes('youtube.com')) {
        videoId = new URLSearchParams(new URL(songURL).search).get('v');
      } else if (songURL.includes('youtu.be')) {
        videoId = songURL.split('youtu.be/')[1].split('?')[0];
      }

      if (videoId) {
        try {
          const videoDetails = await fetchVideoDetails(videoId);
          if (videoDetails) {
            const { title, thumbnails, channelTitle } = videoDetails.snippet;
            const extractedDetails = extractSongDetails(channelTitle, title);
            setSongName(extractedDetails.songName);
            setSongArtist(extractedDetails.songArtist);
            setFetchedTitle(title);
            setFetchedThumbnail(thumbnails);
            setShowFullForm(true);
          }
        } catch (error) {
          console.error('Could not fetch video details:', error);
        }
      }
    } else {
      setIsUrlValid(false);
      setShowFullForm(false);
    }
  };

  const functions = getFunctions();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    onPostingChange(true);

    if (!auth.currentUser) {
      alert('Please log in to submit a song!');
      return;
    }

    const userId = auth.currentUser.uid;
    const timezoneOffset = new Date().getTimezoneOffset();
    const songData = {
      userId,
      songName,
      songArtist,
      URL: songURL,
      description,
      thumbnailURL:
        fetchedThumbnail.maxres?.url ||
        fetchedThumbnail.standard?.url ||
        fetchedThumbnail.high?.url ||
        fetchedThumbnail.medium?.url ||
        fetchedThumbnail.default?.url ||
        '',
      // Don't update timestamp on edit
      ...(isEditing ? {} : { timestamp: serverTimestamp() }),
      timezoneOffset,
    };

    console.log('Song data to be posted:', songData);

    if (isEditing && postToEdit) {
      try {
        const songRef = doc(getFirestore(), 'songs', postToEdit.id);
        await updateDoc(songRef, songData);
        onSuccess();
        setIsLoading(false);
      } catch (error) {
        alert('There was an error updating this song...');
        setIsLoading(false);
        onPostingChange(false);
        console.error('Error with the following song data:', songData, 'Error:', error);
      }
    } else {
      const postSong = httpsCallable(functions, 'postSong');
      try {
        const result = await postSong(songData);
        console.log('PostSong result:', result);
        if (result.data.success) {
          onSuccess();
          setSongName('');
          setSongArtist('');
          setSongURL('');
          setDescription('');
          setIsLoading(false);
        } else {
          alert('Failed to post song:', result.data.message);
        }
      } catch (error) {
        alert('There was an error posting this song...');
        setIsLoading(false);
        onPostingChange(false);
        console.error('Error with the following song data:', songData, 'Error:', error);
      }
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setRemainingChars(MAX_CHARS - e.target.value.length);
  };

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit} className={styles.postform}>
        {isLoading ? (
          <div className={styles.loadingContainer}>
            <p className={styles.postingText}>Posting...</p>
          </div>
        ) : null}
        {!showFullForm && (
          <div className={styles.ctaGroup}>
            <label className={styles.inputGroup}>
              <span className={styles.inputLabelCta}>Youtube URL</span>
              <input
                className={styles.urlInput}
                type='text'
                value={songURL}
                onChange={handleUrlChange}
                placeholder='Enter a YouTube URL'
                tabIndex={0}
                autoFocus
              />
              {!isUrlValid && (
                <span
                  style={{ marginLeft: '24px' }}
                  className={styles.inputLabel}
                >
                  ☝️ Please enter a valid YouTube url
                </span>
              )}
            </label>
          </div>
        )}
        {showFullForm && (
          <div className={styles.fetchedContainer}>
            <div className={styles.fetchedContainerHeader}>
              <button
                type='button'
                onClick={clearUrlInput}
                className={styles.clearButton}
              >
                <Back fill='#171717' />
                <span className={styles.fetchedContainerTitle}>Song info</span>
              </button>
            </div>
            <div className={styles.fetchedContainerBody}>
              <div className={styles.songCluster}>
                {fetchedThumbnail && fetchedThumbnail.default && (
                  <>
                    <div
                      className={styles.thumbnail}
                      style={{
                        backgroundImage: `url(${fetchedThumbnail.default.url})`,
                      }}
                      alt='Video thumbnail'
                    />
                    <div className={styles.fetchedInfo}>
                      <span className={styles.fetchedName}>{fetchedTitle}</span>
                      <Link
                        target='_blank'
                        to={songURL}
                        className={styles.fetchedURL}
                      >
                        {songURL}
                      </Link>
                    </div>
                  </>
                )}
              </div>
              <div className={styles.songInfoCluster}>
                <label className={styles.inputLabel}>
                  Song Name:
                  <input
                    className={styles.songInput}
                    type='text'
                    value={songName}
                    onChange={(e) => setSongName(e.target.value)}
                  />
                </label>
                <label className={styles.inputLabel}>
                  Artist:
                  <input
                    className={styles.songInput}
                    type='text'
                    value={songArtist}
                    onChange={(e) => setSongArtist(e.target.value)}
                  />
                </label>
              </div>
              <label className={styles.inputLabel}>
                What do you have to say about this song?:
                <div className={styles.textBox}>
                  <textarea
                    className={styles.textBoxInner}
                    value={description}
                    maxLength={MAX_CHARS}
                    onChange={handleDescriptionChange}
                  />
                </div>
              </label>
              <p className={styles.remainingChars}>{remainingChars}</p>
              <button className={styles.submitCta} type='submit'>
                {isEditing ? 'Update' : 'Submit'}
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default PostForm;
