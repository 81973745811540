import React, { useState } from 'react';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import Modal from '../utils/Modal';
import styles from './EditProfile.module.scss';
import Edit from '../Icons/Edit';

const EditProfile = ({
  show,
  onClose,
  userId,
  fetchUserDataAndSongs,
  currentUserDisplayName,
  currentAvatar,
}) => {
  const db = getFirestore();

  const [newDisplayName, setNewDisplayName] = useState(currentUserDisplayName);
  const [newAvatar, setNewAvatar] = useState('');

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    console.log('Selected File:', file);

    const storage = getStorage();
    const storageRef = ref(storage, 'avatars/' + userId);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        console.error('Error uploading file:', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            console.log('File available at', downloadURL);
            setNewAvatar(downloadURL);
          })
          .catch((error) => {
            console.error('Error getting download URL:', error);
          });
      }
    );
  };

  const handleEdit = async () => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const fieldsToUpdate = {};
      if (newDisplayName) fieldsToUpdate.displayName = newDisplayName;
      if (newAvatar) fieldsToUpdate.avatar = newAvatar;
      console.log('Updating Firestore Document:', fieldsToUpdate);
      await updateDoc(userDocRef, fieldsToUpdate);
      await fetchUserDataAndSongs();
      onClose();
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  return (
    <Modal size='small' show={show} onClose={onClose} title="Edit your profile">
      
      <div className={styles.container}>
        <div className={styles.top}>
        <span className={styles.formLabel}>Profile pic</span>
          <label className={styles.avatarLabel}>
            <input
              type='file'
              accept='image/*'
              onChange={handleFileUpload}
              className={styles.fileInput}
              value=''
            />
            <div
              className={styles.avatarPreview}
              style={{ backgroundImage: `url(${newAvatar || currentAvatar})` }}
            >
              <Edit fill='#fff' className={styles.editIcon} />
            </div>
          </label>

          <label className={styles.editLabel}>
            <span className={styles.formLabel}>Display Name</span>
            <input
              className={styles.input}
              type='text'
              placeholder='New display name'
              value={newDisplayName}
              onChange={(e) => setNewDisplayName(e.target.value)}
            />
          </label>
        </div>
        <button className={styles.cta} onClick={handleEdit}>
          Save changes
        </button>
      </div>
    </Modal>
  );
};

export default EditProfile;
