import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { auth } from './config/firebase';
import Center from './components/utils/Center';
import Toolbar from './components/Toolbar';
import BottomBar from './components/BottomBar';
import { Helmet } from 'react-helmet';
import './index.scss';
import { useNavigation } from './config/NavigationContext';
import NavigationFrame from './components/NavigationFrame/NavigationFrame';
import ScrollToTop from './config/scrollToTop';
import routes from './config/routes';
import PostFormModal from './components/PostFormModal';

function App({ openSongPage, closeSongPage, currentSong }) {
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const { handleUserPageNav, handleHomeNav, handleNotificationsNav } = useNavigation();

  const [isEditing, setIsEditing] = useState(false);
  const [postToEdit, setPostToEdit] = useState(null);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 820);
  };

  useEffect(() => {
    auth.onAuthStateChanged(() => {
      setLoading(false);
    });

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const routeElements = useRoutes(
    routes.map((route) => ({
      path: route.path,
      element: (
        <route.component
          openSongPage={openSongPage}
          handleUserPageNav={handleUserPageNav}
          handleHomeNav={handleHomeNav}
          handleNotificationsNav={handleNotificationsNav}
          setIsEditing={setIsEditing}
          setPostToEdit={setPostToEdit}
        />
      ),
    }))
  );

  if (loading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Good Song Club</title>
        <link rel='icon' href='https://goodsong.club/favicon.ico' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#000000' />
        <meta name='title' content='Good Song Club' />
        <meta name='description' content='Share good songs from YouTube.' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://goodsong.club/' />
        <meta property='og:title' content='Good Song Club' />
        <meta
          property='og:description'
          content='Share good songs from YouTube.'
        />
        <meta property='og:image' content='https://goodsong.club/ogimage.png' />
        <meta property='twitter:card' content='summary_large_image' />
        <meta property='twitter:url' content='https://goodsong.club/' />
        <meta property='twitter:title' content='Good Song Club' />
        <meta
          property='twitter:description'
          content='Share good songs from YouTube.'
        />
        <meta
          property='twitter:image'
          content='https://goodsong.club/ogimage.png'
        />
        <link
          rel='apple-touch-icon'
          href='https://goodsong.club/logo192.png'
        />
        <link rel='manifest' href='https://goodsong.club/manifest.json' />
      </Helmet>


        {isMobile ? (
          <>
            {!currentSong && <BottomBar />}
            <div className="navWindow">
              {!currentSong && (
                <>
                  <NavigationFrame openSongPage={openSongPage} />
                  {routeElements}
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <Toolbar className='toolbar' handleUserPageNav={handleUserPageNav} />
            <div className='navWindow'>
              <NavigationFrame openSongPage={openSongPage} />
              {routeElements}
            </div>
          </>
        )}
        <PostFormModal
          show={isEditing}
          onClose={() => setIsEditing(false)}
          isEditing={isEditing}
          postToEdit={postToEdit}
        />
      
    </>
  );
}

export default App;
