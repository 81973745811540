import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  getFirestore,
  query,
  where,
  collection,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const db = getFirestore();
const functions = getFunctions();

const SongsContext = createContext();

export const useSongs = () => {
  return useContext(SongsContext);
};

export const SongsProvider = ({ children }) => {
  const [songs, setSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);

  useEffect(() => {
    const fetchSongs = () => {
      const songCollection = collection(db, 'songs');
      const unsubscribe = onSnapshot(songCollection, (snapshot) => {
        const songList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSongs(songList);
      });
      return unsubscribe;
    };

    const unsubscribe = fetchSongs();
    return () => unsubscribe();
  }, []);

  const getSongById = async (songId) => {
    try {
      const songDoc = await getDoc(doc(db, 'songs', songId));
      if (songDoc.exists()) {
        return { id: songDoc.id, ...songDoc.data() };
      } else {
        console.error('No such song!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching song:', error);
      return null;
    }
  };

  const fetchSongById = async (songId) => {
    try {
      const songDoc = await getDoc(doc(db, 'songs', songId));
      if (songDoc.exists()) {
        return { id: songDoc.id, ...songDoc.data() };
      } else {
        console.error('No such song!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching song:', error);
      return null;
    }
  };

  const deleteSong = async (id) => {
    try {
      await deleteDoc(doc(db, 'songs', id));
      setSongs((prevSongs) => prevSongs.filter((song) => song.id !== id));
    } catch (error) {
      console.error('Error deleting song:', error);
    }
  };

  const likeSong = async (songId, userId) => {
    console.log(`Liking song: ${songId} by user: ${userId}`);
    const songRef = doc(db, 'songs', songId);
    const userRef = doc(db, 'users', userId);
  
    try {
      const batch = writeBatch(db);
  
      batch.update(songRef, {
        likes: arrayUnion(userId),
      });
  
      const likedAt = Timestamp.now();
      batch.update(userRef, {
        likedSongs: arrayUnion({ songId, likedAt }),
      });
  
      await batch.commit();
  
      const likeSongNotificationFunction = httpsCallable(functions, 'likeSongNotification');
      console.log('Calling likeSongNotification function with songId:', songId);
      try {
        const result = await likeSongNotificationFunction({ songId });
        console.log('Like notification function result:', result.data);
      } catch (error) {
        console.error('Error calling like notification function:', error);
        if (error.details) {
          console.error('Error details:', error.details);
        }
      }
    } catch (error) {
      console.error('Error liking the song:', error);
    }
  };

  const unlikeSong = async (songId, userId) => {
    const songRef = doc(db, 'songs', songId);
    const userRef = doc(db, 'users', userId);

    try {
      const batch = writeBatch(db);

      batch.update(songRef, {
        likes: arrayRemove(userId),
      });

      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();
      const likedSongObject = userData.likedSongs.find(
        (obj) => obj.songId === songId
      );

      if (likedSongObject) {
        batch.update(userRef, {
          likedSongs: arrayRemove(likedSongObject),
        });
      }

      await batch.commit();
    } catch (error) {
      console.error('Error unliking the song:', error);
    }
  };

  const addComment = async (songId, newComment) => {
    console.log(
      `Adding comment to song: ${songId} by user: ${newComment.userId}`
    );
    const songRef = doc(db, 'songs', songId);

    if (
      !songId ||
      !newComment.userId ||
      !newComment.displayName ||
      !newComment.text
    ) {
      console.error('Error: One or more required fields are undefined.');
      return;
    }

    const commentToAdd = {
      userId: newComment.userId,
      userName: newComment.displayName,
      commentText: newComment.text,
      timestamp: Timestamp.now(),
      likes: [],
    };

    try {
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData) {
        const updatedComments = songData.comments
          ? [...songData.comments, commentToAdd]
          : [commentToAdd];
        await updateDoc(songRef, { comments: updatedComments });

        console.log('Comment added successfully:', commentToAdd);

        const commentNotificationFunction = httpsCallable(functions, 'commentNotification');
        try {
          const result = await commentNotificationFunction({
            songId,
            commentText: newComment.text,
          });
          console.log('Comment notification created:', result.data);
        } catch (error) {
          console.error('Error creating comment notification:', error);
          if (error.details) {
            console.error('Error details:', error.details);
          }
        }
      } else {
        console.error('Error: The song does not exist.');
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const deleteComment = async (songId, commentIndex) => {
    const songRef = doc(db, 'songs', songId);
    try {
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = [...songData.comments];
        updatedComments.splice(commentIndex, 1);
        await updateDoc(songRef, { comments: updatedComments });
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const fetchSongsByUser = async (userId) => {
    try {
      const songsQuery = query(
        collection(db, 'songs'),
        where('userId', '==', userId)
      );
      const querySnapshot = await getDocs(songsQuery);
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error('Error fetching songs by user:', error);
      return [];
    }
  };

  const likeComment = async (songId, commentIndex, userId) => {
    const songRef = doc(db, 'songs', songId);
    try {
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = [...songData.comments];
        if (updatedComments[commentIndex].likes) {
          updatedComments[commentIndex].likes.push(userId);
        } else {
          updatedComments[commentIndex].likes = [userId];
        }

        await updateDoc(songRef, { comments: updatedComments });

        // Removed the Cloud Function call for liking comments
      }
    } catch (error) {
      console.error('Error liking the comment:', error);
    }
  };

  const unlikeComment = async (songId, commentIndex, userId) => {
    const songRef = doc(db, 'songs', songId);
    try {
      const songSnapshot = await getDoc(songRef);
      const songData = songSnapshot.data();

      if (songData && songData.comments) {
        const updatedComments = [...songData.comments];
        const index = updatedComments[commentIndex].likes.indexOf(userId);
        if (index !== -1) {
          updatedComments[commentIndex].likes.splice(index, 1);
          await updateDoc(songRef, { comments: updatedComments });
        }
      }
    } catch (error) {
      console.error('Error unliking the comment:', error);
    }
  };

  const value = {
    songs,
    currentSong,
    setCurrentSong,
    setSongs,
    getSongById,
    fetchSongById,
    deleteSong,
    likeSong,
    unlikeSong,
    addComment,
    deleteComment,
    likeComment,
    unlikeComment,
    fetchSongsByUser,
  };

  return (
    <SongsContext.Provider value={value}>{children}</SongsContext.Provider>
  );
};

export default SongsProvider;