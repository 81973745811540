import React from 'react';
import PropTypes from 'prop-types';
import styles from './UserListItem.module.scss';
import { useNavigation } from '../../config/NavigationContext';

const UserListItem = ({ user, handleUserPageNavFromSong, onCloseModal }) => {
  const { handleUserPageNav } = useNavigation();

  const handleClick = (e) => {
    e.preventDefault();
    if (handleUserPageNavFromSong) {
      handleUserPageNavFromSong(user.uid);
    } else {
      handleUserPageNav(user.uid);
    }
    if (onCloseModal) {
      onCloseModal(); // Close the modal
    }
  };

  return (
    <div className={styles.listItem} onClick={handleClick}>
      <img
        className={styles.avatar}
        src={user.avatar}
        alt={user.displayName}
        title={user.displayName}
      />
      <span className={styles.displayName}>{user.displayName}</span>
    </div>
  );
};

// Add PropTypes for type safety
UserListItem.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
  }).isRequired,
  handleUserPageNavFromSong: PropTypes.func,
  onCloseModal: PropTypes.func, // Add PropTypes for the callback
};

export default UserListItem;
