import React, { useEffect } from 'react';
import styles from './AboutPage.module.scss';
import { Link } from 'react-router-dom';

function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.container}>
     
     <p> 
      The best music comes not from cold algorithms but from warm hearts. 
     </p>
<hr />
      <p>
        Good Song Club was conceived, developed, and maintained in beautiful
        Philadelphia by{' '}
        <Link to='https://www.robysaavedra.com' target='_blank'>
          Roby Saavedra
        </Link>
        .
      </p>

        <Link to='mailto:hello@goodsong.club' target='_blank'>
          hello@goodsong.club
        </Link>
      <p> 
      
     </p>
    </div>
  );
}

export default AboutPage;
