import React, { useEffect, useState, useRef, useCallback } from 'react';
import moment from 'moment';
import { useNotifications } from '../../config/NotificationContext';
import { useUser } from '../../config/UserContext';
import styles from './NotificationsPage.module.scss';
import Check from '../Icons/Check';
import Empty from '../Icons/Empty';

const NotificationsPage = ({ openSongPage, handleUserPageNav }) => {
  const { getUserDetailsById } = useUser();
  const { notifications, markAllAsRead, markNotificationAsRead, fetchMoreNotifications } = useNotifications();

  const [userDetails, setUserDetails] = useState({});
  const observer = useRef();

  const lastNotificationElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchMoreNotifications();
        }
      });
      if (node) observer.current.observe(node);
    },
    [fetchMoreNotifications]
  );

  const handleNotificationClick = async (notification) => {
    if (!notification.isRead) {
      await markNotificationAsRead(notification.id);
    }

    switch (notification.type) {
      case 'newSong':
      case 'like':
      case 'comment':
      case 'commentLike':
        openSongPage(notification.relatedSongId);
        break;
      case 'follow':
        handleUserPageNav(notification.senderId);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const userDetailsMap = {};

      for (const notification of notifications) {
        const userId = notification.senderId;
        if (userId && !userDetailsMap[userId]) {
          const details = await getUserDetailsById(userId);
          userDetailsMap[userId] = details;
        }
      }

      setUserDetails(userDetailsMap);
    };

    fetchUserDetails();
  }, [notifications, getUserDetailsById]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.notificationsPage}>
      <div className={styles.header}>
        <h3 className={styles.pageTitle}>Notifications</h3>
        <button onClick={markAllAsRead} className={styles.markAsReadButton}>
          <Check fill='#171717' />
          Mark All As Read
        </button>
      </div>
      <ul className={styles.container}>
        {notifications.length > 0 ? (
          notifications.map((notification, index) => {
            if (notifications.length === index + 1) {
              return (
                <li
                  ref={lastNotificationElementRef}
                  key={notification.id}
                  className={`${styles.notifContainer} ${!notification.isRead ? styles.unread : ''}`}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <img
                    className={styles.avatar}
                    src={userDetails[notification.senderId]?.avatar || '../avatar.png'}
                    alt='User avatar'
                  />
                  <div className={styles.notifBody}>
                    <p className={styles.notifCopy}>{notification.content}</p>
                    {notification.relatedSongId && notification.metadata?.songTitle && (
                      <div className={styles.songInfo}>
                        {notification.metadata?.thumbnailURL && (
                          <img
                            src={notification.metadata.thumbnailURL}
                            alt={notification.metadata.songTitle}
                            className={styles.songThumbnail}
                          />
                        )}
                        <span className={styles.songTitle}>
                          {notification.metadata.artistName} - {notification.metadata.songTitle}
                        </span>
                      </div>
                    )}
                    {notification.metadata?.commentPreview && (
                      <p className={styles.commentText}>"{notification.metadata.commentPreview}"</p>
                    )}
                    <span className={styles.notifTime}>
                      {moment(notification.timestamp.toDate()).fromNow()}
                    </span>
                  </div>
                </li>
              );
            } else {
              return (
                <li
                  key={notification.id}
                  className={`${styles.notifContainer} ${!notification.isRead ? styles.unread : ''}`}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <img
                    className={styles.avatar}
                    src={userDetails[notification.senderId]?.avatar || '../avatar.png'}
                    alt='User avatar'
                  />
                  <div className={styles.notifBody}>
                    <p className={styles.notifCopy}>{notification.content}</p>
                    {notification.relatedSongId && notification.metadata?.songTitle && (
                      <div className={styles.songInfo}>
                        {notification.metadata?.thumbnailURL && (
                          <img
                            src={notification.metadata.thumbnailURL}
                            alt={notification.metadata.songTitle}
                            className={styles.songThumbnail}
                          />
                        )}
                        <span className={styles.songTitle}>
                          {notification.metadata.artistName} - {notification.metadata.songTitle}
                        </span>
                      </div>
                    )}
                    {notification.metadata?.commentPreview && (
                      <p className={styles.commentText}>"{notification.metadata.commentPreview}"</p>
                    )}
                    <span className={styles.notifTime}>
                      {moment(notification.timestamp.toDate()).fromNow()}
                    </span>
                  </div>
                </li>
              );
            }
          })
        ) : (
          <div className={styles.emptyState}>
            <Empty />
            <p>No notifications!</p>
          </div>
        )}
      </ul>
    </div>
  );
};

export default NotificationsPage;
