import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { getFirestore, collection, query, where, orderBy, limit, startAfter, getDocs, doc, updateDoc, writeBatch } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const NotificationsContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationsContext);
};

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [lastNotification, setLastNotification] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const db = getFirestore();
  const auth = getAuth();

  const fetchInitialNotifications = useCallback(async (user) => {
    const notificationsQuery = query(
      collection(db, 'notifications'),
      where('recipientId', '==', user.uid),
      orderBy('timestamp', 'desc'),
      limit(30)
    );

    const querySnapshot = await getDocs(notificationsQuery);
    const notificationsList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    setNotifications(notificationsList);
    setLastNotification(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setHasMore(querySnapshot.docs.length === 30);
  }, [db]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
      if (user) {
        fetchInitialNotifications(user);
      }
    });

    return () => unsubscribe();
  }, [auth, fetchInitialNotifications]);

  const fetchMoreNotifications = async () => {
    if (!hasMore || !lastNotification || !currentUser) return;

    const notificationsQuery = query(
      collection(db, 'notifications'),
      where('recipientId', '==', currentUser.uid),
      orderBy('timestamp', 'desc'),
      startAfter(lastNotification),
      limit(30)
    );

    const querySnapshot = await getDocs(notificationsQuery);
    const notificationsList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    setNotifications(prevNotifications => [
      ...prevNotifications,
      ...notificationsList,
    ]);
    setLastNotification(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setHasMore(querySnapshot.docs.length === 30);
  };

  const markAllAsRead = async () => {
    if (!currentUser) return;
    const batch = writeBatch(db);
    notifications.forEach(notification => {
      if (!notification.isRead) {
        const notificationRef = doc(db, 'notifications', notification.id);
        batch.update(notificationRef, { isRead: true });
      }
    });

    await batch.commit();

    setNotifications(prevNotifications =>
      prevNotifications.map(notification => ({
        ...notification,
        isRead: true,
      }))
    );
  };

  const markNotificationAsRead = async (notificationId) => {
    const notificationRef = doc(db, 'notifications', notificationId);
    await updateDoc(notificationRef, { isRead: true });

    setNotifications(prevNotifications =>
      prevNotifications.map(notification =>
        notification.id === notificationId
          ? { ...notification, isRead: true }
          : notification
      )
    );
  };

  const hasUnreadNotifications = useMemo(() => {
    return notifications.some(notification => !notification.isRead);
  }, [notifications]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        hasUnreadNotifications,
        markAllAsRead,
        markNotificationAsRead,
        fetchMoreNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
