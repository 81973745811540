import React from 'react';

const Send = ({ fill = '#D4D4D4' }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_1023_470)'>
      <path d='M2.01 21L23 12L2.01 3L2 10L17 12L2 14L2.01 21Z' fill={fill} />
    </g>
    <defs>
      <clipPath id='clip0_1023_470'>
        <rect width='24' height='24' fill={fill} />
      </clipPath>
    </defs>
  </svg>
);

export default Send;
