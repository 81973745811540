import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styles from './SongListHome.module.scss';
import { useUser } from '../../config/UserContext';
import { useSongs } from '../../config/SongContext';
import SongListDay from '../SongListDay';
import { Link } from 'react-router-dom';
import { useNavigation } from '../../config/NavigationContext';
import BrandHead from '../BrandHead/BrandHead';

const SongListHome = ({ setNavContent, openSongPage }) => {
  const { handleUserPageNav } = useNavigation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { currentUser } = useUser();
  const { songs, deleteSong } = useSongs();
  const [daysToShow, setDaysToShow] = useState(7);

  const groupedByDay = songs.reduce((acc, song) => {
    const timestamp =
      song.timestamp &&
      'seconds' in song.timestamp &&
      'nanoseconds' in song.timestamp
        ? song.timestamp.toDate()
        : new Date();

    const date = moment(timestamp).format('dddd, MMMM Do YYYY');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(song);
    return acc;
  }, {});

  const lastDays = Array.from({ length: daysToShow }, (_, i) => {
    return moment().subtract(i, 'days').format('dddd, MMMM Do YYYY');
  });

  const handleLoadMore = () => {
    setDaysToShow((prevDaysToShow) => prevDaysToShow + 7);
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className={styles.container}>
        <BrandHead/>
        <ul className={styles.songList}>
          {lastDays.map((day) => (
            <SongListDay
              key={day}
              day={day}
              songs={groupedByDay[day]}
              onDelete={deleteSong}
              currentUser={currentUser}
              setNavContent={setNavContent}
              openSongPage={openSongPage} // Pass openSongPage to SongListDay
              handleUserPageNav={handleUserPageNav}
            />
          ))}
        </ul>
        <div className={styles.loadMoreContainer}>
          <button onClick={handleLoadMore} className={styles.loadMoreButton}>
            Load more...
          </button>
        </div>
        <div className={styles.infoBox}>
          <div className={styles.infoBoxInner}>
            <div>
              <Link to={`/about`}>About</Link>
            </div>
            <div>{currentYear}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SongListHome;
