import { useState } from 'react';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth, Providers } from '../../config/firebase';

import styles from './auth.module.scss';

const AuthContainer = (props) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(false);

  const signInWithGoogle = () => {
    setDisabled(true);
    signInWithPopup(auth, Providers.google)
      .then(() => {
        setDisabled(false);
        console.info('TODO: navigate to authenticated screen');
        navigate('/');
      })
      .catch((error) => {
        setErrorMessage(error.code + ': ' + error.message);
        setDisabled(false);
      });
  };

  return (
    <>
      <button
        className={styles.authButton}
        onClick={signInWithGoogle}
        disabled={disabled}
      >
        <span className={styles.ninetyDegrees}>Login / Sign up</span>
      </button>
      {errorMessage}
    </>
  );
};

export default AuthContainer;
