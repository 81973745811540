import React, { useEffect, useState } from 'react';
import styles from './BrandHead.module.scss';
import BrandHeadLogo from '../Icons/BrandHeadLogo';
import { useSongs } from '../../config/SongContext';

function BrandHead() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const { songs } = useSongs();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const totalSongs = songs.length;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.stacked}>

        <span className={styles.strong}>{totalSongs}</span> <p className={styles.paragraph}>songs shared</p>
        </div>
        <p><span className={styles.strong}>{currentTime.toLocaleTimeString()}</span></p>
      </div>
      <div className={styles.body}>
        <p>Welcome to</p>
        <BrandHeadLogo />
      </div>
    </div>
  );
}

export default BrandHead;
