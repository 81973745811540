import React, { useState, useCallback, useRef, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './config/UserContext';
import { SongsProvider } from './config/SongContext';
import { NavigationProvider } from './config/NavigationContext';
import { NotificationsProvider } from './config/NotificationContext';
import { AuthWrapper } from './config/AuthWrapper';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import SongPage from './components/SongPage/SongPage';
import Home from './components/Home/Home';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './config/firebase';

const getSongById = async (songId) => {
  try {
    if (typeof songId !== 'string' || songId.trim() === '') {
      throw new Error('Invalid song ID');
    }
    const songDoc = await getDoc(doc(db, 'songs', songId));
    if (songDoc.exists()) {
      return { id: songDoc.id, ...songDoc.data() };
    } else {
      console.error('No such song!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching song:', error);
    return null;
  }
};

const SongContainer = ({ openSongPage, closeSongPage, currentSong }) => {
  const songPageRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!currentSong && songPageRef.current && songPageRef.current.closePlayer) {
      songPageRef.current.closePlayer();
    }
  }, [currentSong]);

  return (
    <div className='songPageContainer' style={{ display: currentSong || !isMobile ? 'block' : 'none' }}>
      {currentSong ? (
        <SongPage
          ref={songPageRef}
          song={currentSong}
          onClose={closeSongPage}
          openSongPage={openSongPage}
        />
      ) : (
        !isMobile && <Home />
      )}
    </div>
  );
};

const Root = () => {
  const [currentSong, setCurrentSong] = useState(null);

  const closeSongPage = useCallback(() => {
    setCurrentSong(null);
    window.history.pushState(null, '', '/'); // Remove the song ID from the URL when the song page is closed
  }, []);

  const openSongPage = useCallback(
    async (songId) => {
      if (currentSong && currentSong.id === songId) {
        return; // If the same song is already open, do nothing
      }

      if (currentSong) {
        closeSongPage();
      }

      const song = await getSongById(songId);
      if (song) {
        setCurrentSong(song);
        window.history.pushState(null, '', `?songId=${songId}`); // Update the URL with the song ID
      }
    },
    [currentSong, closeSongPage]
  );

  return (
    <React.StrictMode>
      <Provider store={store}>
        <UserProvider>
          <SongsProvider>
            <AuthWrapper>
              <NotificationsProvider>
                <BrowserRouter>
                  <NavigationProvider>
                    <div className='appWrapper'>
                      <App
                        openSongPage={openSongPage}
                        closeSongPage={closeSongPage}
                        currentSong={currentSong}
                      />
                      <SongContainer
                        openSongPage={openSongPage}
                        closeSongPage={closeSongPage}
                        currentSong={currentSong}
                      />
                    </div>
                  </NavigationProvider>
                </BrowserRouter>
              </NotificationsProvider>
            </AuthWrapper>
          </SongsProvider>
        </UserProvider>
      </Provider>
    </React.StrictMode>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(<Root />);

reportWebVitals();
